import {createRouter, createWebHistory} from 'vue-router'

// Public pages
import PrivacyPolicyPage from "./public/PrivacyPolicy/PrivacyPolicy";
import ImprintPage from "./public/Imprint/Imprint";

// Settings
import {accountSettingsRoutes, companySettingsRoutes, developerSettingsRoutes, adminSettingsRoutes} from "@dutypay/settings-pages";

export const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    scrollBehavior (to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return { left: 0, top: 0 }
        }
    },
    routes: [
        // ----------------
        //  PUBLIC PAGES
        // ----------------
        {
            path: '/imprint',
            name: 'Imprint',
            component: ImprintPage,
            // meta: {
            //   public: true
            // }
        },
        {
            path: '/privacy-policy',
            name: 'Privacy Policy',
            component: PrivacyPolicyPage,
            // meta: {
            //   public: true
            // }
        },

        // ----------------
        //  SETTINGS
        // ----------------
        ...accountSettingsRoutes,
        ...companySettingsRoutes,
        ...developerSettingsRoutes,
        ...adminSettingsRoutes
    ]
});
